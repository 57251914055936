import axios from '@axios'
import handlerDataSearch from '@/handlers/handlerDataSearch'

export default {
    namespaced: true,
    state: {
        searchData: null,
    },
    getters: {},
    mutations: {
        setSearchData(state, data) {
            state.searchData = data
        }
    },
    actions: {
        fetchCompanies(ctx, params) {
            return new Promise((resolve, reject) => {
              axios
                .get('/api/v1/cases/company', { params: { perPage: 200, page: 1, companyType: 'ARL', only_active: true } })
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        serviceTypes(ctx) {
            return new Promise((resolve, reject) => {
              axios
                .get('/api/v1/core/type_value/service_type', { params: { perPage: 50, page: 1 } })
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        invoiceGroups(ctx) {
            return new Promise((resolve, reject) => {
              axios
                .get('/api/v1/core/type_value/invoice_group', { params: { perPage: 50, page: 1 } })
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        fetchElements(ctx, params) {
            const currentParams = handlerDataSearch(params)
            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/v1/billing/search`, {params: currentParams})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        createInvoices(ctx, params) {
          console.log('Sending selectedItemsIds:', params.selectedItemsIds);
          console.log('Sending queryParams:', params.queryParams);            
        
          return new Promise((resolve, reject) => {
            axios
              .post(
                `/api/v1/billing/search`, 
                { 
                  selectedItemsIds: params.selectedItemsIds
                },  
                { 
                  params: params.queryParams, 
                  headers: {
                    'Content-Type': 'application/json'  
                  }
                }
              )
              .then(response => resolve(response))
              .catch(error => {
                console.error('Error fetching elements:', error);
                reject(error);
              });
          });
        },
          
    },
}
