<template>
  <div>
    <search
      :element-data="elementData"
      :arl-list="arlList"
      :size-list="sizeList"
      :service-type-list="serviceTypeList"
      :resources-element-name="RESOURCES_ELEMENT_NAME"
    />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t('table.search.show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50" />
            <label>{{ $t('table.search.entries') }}</label>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex justify-content-start mb-1 mb-md-0">
            <b-form-input
              v-model="query"
              class="d-inline-block mr-1"
              :placeholder="$t('table.search.search')" />
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click="export2excel">
              <span class="text-nowrap">{{ $t('list.export') }}</span>
            </b-button>
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :disabled="disableSending"
              @click="send">
              <span class="text-nowrap">{{ $t('billing.search.send') }}</span>
            </b-button>
          </b-col>
        </b-row>
      </div>

      <!-- Table with Checkbox Selection -->
      <vue-double-scrollbar v-if="tableDataLoaded">
        <b-table
          ref="refElementsListTable"
          class="position-relative"
          :items="itemsFormatted"
          :fields="tableColumns"
          primary-key="other"
          show-empty
          :empty-text="$t(RESOURCES_ELEMENT_NAME + '.search.not_found')">
          
          <!-- Checkbox de selección en la cabecera -->
          <template v-slot:head(select)="data">
            <span>
              <b-form-checkbox
                v-model="allSelectedOnPage"
                @change="toggleSelectAllOnPage"
                :indeterminate="isIndeterminate"
              />
              <b-tooltip target="toggle-select" triggers="hover">
                <span class="align-middle ml-50">
                  Seleccionar / deseleccionar todo
                </span>
              </b-tooltip>
            </span>
          </template>
          

          <!-- Checkbox de selección en cada fila -->
          <template #cell(select)="data">
            <b-form-checkbox
            v-model="selectedItems"
            :value="data.item.other"
            @change="updateSelectAllStatus"
          />
          </template>

          <!-- column: name -->
          <template #cell(name)="data">
            <b-badge
              pill
              class="text-capitalize">
              {{ data.item.name }}
            </b-badge>
          </template>
        </b-table>
      </vue-double-scrollbar>

      <!-- Pagination -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-if="tableDataLoaded"
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="total"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item">
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18" />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BBadge,
  BPagination,
  BTooltip,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import * as XLSX from 'xlsx'
import moment from 'moment'
import { mapState, mapMutations } from 'vuex'
import ElementStoreModule from './storeModule'
import Search from './Search.vue'
import casesStoreModule from './storeModule'
import SessionStorage from '@/handlers/SessionStorage'

export default {
  components: {
    Search,
    BTooltip,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BPagination,
    vSelect,
    BFormCheckbox,
  },
  data() {
    return {
      ELEMENT_APP_STORE_MODULE_NAME: '',
      CASES_APP_STORE_MODULE_NAME: '',
      RESOURCES_ELEMENT_NAME: '',
      items: [],
      selectedItems: [],
      itemsFormatted: [],
      total: 1,
      perPage: 1000,
      currentPage: 1,
      currentPath: window.location.pathname,
      perPageOptions: [5, 10, 25, 50, 100, 500, 1000],
      query: '',
      selected: [], 
      allSelectedOnPage: false,
      selected_element: {},
      currentSelection: {},
      tableColumns: [],
      elementData: {},
      arlList: [],
      serviceTypeList: [],
      sizeList: [],
      disableSending: true,
      tableDataLoaded: false,
      isIndeterminate: false,
    }
  },
  created() {
    this.RESOURCES_ELEMENT_NAME = 'billing'
    this.ELEMENT_APP_STORE_MODULE_NAME = `app-${this.RESOURCES_ELEMENT_NAME}`
    this.CASES_APP_STORE_MODULE_NAME = 'app-case'
  },
  computed: {
    ...mapState('caseSearch', ['searchData']),
    currentPageItems() {
      return this.itemsFormatted.map(item => item.other);
    },
  },
  watch: {
    perPage(val) {
      this.fetchElements()
    },
    currentPage(val) {
      this.fetchElements()
    },
    query(val) {
      this.fetchElements()
    },
    'filter.filter_date_from': function () {
      this.fetchElements()
    },
    'filter.filter_date_to': function () {
      this.fetchElements()
    },
    selected() {
      this.updateSelectAllOnPage();
    },
    itemsFormatted: {
      handler() {
        this.updateSelectAllStatus();
      },
      deep: true,
    },
  },
  async mounted() {
    this.search_storage = new SessionStorage('avenir')

    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.registerModule(this.ELEMENT_APP_STORE_MODULE_NAME, ElementStoreModule)

    // Register cases store module
    if (!store.hasModule(this.CASES_APP_STORE_MODULE_NAME)) store.registerModule(this.CASES_APP_STORE_MODULE_NAME, casesStoreModule)

    this.arlList = await this.fetchARLs()
    this.serviceTypeList = await this.fetchServiceTypes()
    this.sizeList = await this.fetchInvoiceGroups()

    this.tableColumns = [
      { key: 'select', label: '' }, 
      { key: 'patient_document', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.patient_document`), sortable: true },
      { key: 'patient_name', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.patient_name`), sortable: true },
      { key: 'arl_name', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.arl_name`), sortable: true },
      { key: 'followup_type', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.followup_type`), sortable: true },
      { key: 'service_date', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.service_date`), sortable: true },
      { key: 'service_type_name', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.service`), sortable: true },
      { key: 'quantity', label: 'CAN', sortable: true },
      { key: 'value', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.value`), sortable: true },
      { key: 'due_date', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.due_date`), sortable: true },
      { key: 'authorization', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.authorization`), sortable: true },
      { key: 'support', label: this.$t(`${this.RESOURCES_ELEMENT_NAME}.object.support`), sortable: true },
    ]

    const initialTypeList = []

    this.serviceTypeList.forEach(item => {
      initialTypeList.push(item.id)
    })

    if (this.searchData) {
      this.elementData = this.searchData
    } else {
      this.elementData = {
        arl_id: null,
        from: moment().subtract(30, 'days').toDate(),
        until: moment().toDate(),
        size: this.sizeList[0].value,
        rips: 1,
        service_types: initialTypeList,
      }
    }

    const data = JSON.parse(sessionStorage.getItem(this.currentPath))
    data && (this.query = data.query)
    await this.loadSearch()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
    if (store.hasModule(this.CASES_APP_STORE_MODULE_NAME)) store.unregisterModule(this.CASES_APP_STORE_MODULE_NAME)
  },
  methods: {
    ...mapMutations('caseSearch', ['setSearchData']),
    logSelectedChange(id) {
    },
    filterJson(jsonData) {
      const filterList = this.tableColumns.map(a => a.key)
      return jsonData.map(v => filterList.map(j => v[j]))
    },
    async loadSearch() {
      const search = this.search_storage.getValueStorage()
      this.currentPage = search.page || 1
      this.perPage = search.perPage || 1000
      this.query = search.query || null
      this.elementData.from = search.from || null
      this.elementData.until = search.until || null
    },
    getHeader() {
      const res = {}
      for (let i = 0; i < this.tableColumns.length; i++) {
        res[this.tableColumns[i].key] = this.tableColumns[i].label
      }
      return res
    },
    format(jsonData) {
      jsonData.forEach(item => {
        if (item.due_date) {
          item.due_date = moment(item.due_date).format('DD/MM/YYYY')
        }
        if (item.service_date) {
          item.service_date = moment(item.service_date).format('DD/MM/YYYY')
        }
      })
    },
    async send() {
      await this.createInvoices()
    },
    export2excel() {
      store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchElements`, {
          queryParams: {
            perPage: 10000,
            page: 1,
            from: this.elementData.from,
            until: this.elementData.until,
            arl_id: this.elementData.arl_id,
            service_types: this.elementData.service_types,
            size: this.elementData.size,
            rips: this.elementData.rips,
            query: this.query,
          },
        })
        .then(response => {
          const filename = this.$t(`${this.RESOURCES_ELEMENT_NAME}.search.received_xls_name`)
          this.format(response.data.items)
          response.data.items.unshift(this.getHeader())
          const list = this.filterJson(response.data.items).map(item => {
            const { [Object.keys(item)[0]]: _, ...rest } = item;
            return rest;
          });
          const data = XLSX.utils.json_to_sheet(list, { skipHeader: true })
          const workbook = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(workbook, data, filename)
          XLSX.writeFile(workbook, `${filename}.xlsx`)
        })
        .catch(() => {
          console.error('Error fetching elements')
        })
    },
    fetchElements() {
      this.setSearchData({ ...this.elementData })
      sessionStorage.setItem(this.currentPath, JSON.stringify({ query: this.query }))

      store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/fetchElements`, {
          queryParams: {
            perPage: this.perPage,
            page: this.currentPage,
            from: this.elementData.from,
            until: this.elementData.until,
            arl_id: this.elementData.arl_id,
            service_types: this.elementData.service_types,
            size: this.elementData.size,
            rips: this.elementData.rips,
            query: this.query,
          },
        })
        .then(response => {
          this.items = response.data.items;
          this.total = response.data.total;
          this.itemsFormatted = this.items.map(item => ({
            ...item,
            other: item.document,
            value: item.quantity ? item.value * item.quantity : item.value,
            date: moment(item.date).format('DD/MM/YYYY hh:mm A'),
            due_date: moment(item.due_date).format('DD/MM/YYYY'),
            service_date: moment(item.service_date).format('DD/MM/YYYY'),
          }));

          this.updateSelectAllStatus();
          this.tableDataLoaded = true;
          this.disableSending = false
        })
        .catch(() => {
          this.itemsFormatted = [];
          console.error('Error fetching elements');
        });
    },
    toggleSelectAllOnPage() {
      if (this.allSelectedOnPage) {
        this.selectedItems = [...new Set([...this.selectedItems, ...this.currentPageItems])];
      } else {
        this.selectedItems = this.selectedItems.filter(item => !this.currentPageItems.includes(item));
      }
      this.updateSelectAllStatus();
    },
    updateSelectAllStatus() {
      const selectedOnPage = this.currentPageItems.filter(item => this.selectedItems.includes(item));
      this.allSelectedOnPage = selectedOnPage.length === this.currentPageItems.length;
      this.isIndeterminate = selectedOnPage.length > 0 && selectedOnPage.length < this.currentPageItems.length;
    },
    updateSelectAllOnPage() {
      const currentPageItems = this.itemsFormatted.map(item => item.other);
      this.allSelectedOnPage = currentPageItems.every(itemOther => this.selectedItems.includes(itemOther));
    },
    toggleItemSelection(item) {
      const index = this.selectedItems.indexOf(item.other);
      if (index > -1) {
        this.selectedItems.splice(index, 1);
      } else {
        this.selectedItems.push(item.other);
      }
      this.updateSelectAllStatus();
      this.disableSending = this.selectedItems.length === 0;
    },
    createInvoices() {
        store
        .dispatch(`${this.ELEMENT_APP_STORE_MODULE_NAME}/createInvoices`, {
          selectedItemsIds: this.selectedItems,
          queryParams: {
            perPage: this.perPage,
            page: this.currentPage,
            from: this.elementData.from,
            until: this.elementData.until,
            arl_id: this.elementData.arl_id,
            service_types: this.elementData.service_types,
            size: this.elementData.size,
            rips: this.elementData.rips,
            query: this.query,
          }
        })
        .then(response => {
          this.items = response.data.items
          this.total = response.data.total
          if (this.total > 0) {
            const { arl_id } = this.items[0]
            const date = moment(this.items[0].date).format('YYYY-MM-DDTHH:mm:ss')
            this.$router.push({ name: 'apps-billing-invoice', params: { arl_id, date } })
          }
        })
        .catch(error => {
          console.error('Error creating invoices:', error);
        });
    },
    async fetchServiceTypes() {
      return store.dispatch(`${this.CASES_APP_STORE_MODULE_NAME}/serviceTypes`, {}).then(response => response.data.items.filter(item => item.key != 'INTERCONSULT')).catch(error => {
        if (error.response.status === 404) {
          return undefined
        }
      })
    },
    async fetchARLs() {
      return store.dispatch(`${this.CASES_APP_STORE_MODULE_NAME}/fetchCompanies`, { }).then(response => {
        const list = response.data.items
        list.unshift({ id: null, name: this.$t('list.defaultOption') })
        return list
      }).catch(error => {
        if (error.response.status === 404) {
          return undefined
        }
      })
    },
    async fetchInvoiceGroups() {
      return store.dispatch(`${this.CASES_APP_STORE_MODULE_NAME}/invoiceGroups`, {}).then(response => response.data.items).catch(error => {
        if (error.response.status === 404) {
          return undefined
        }
      })
    },
    formatFullDate(element, field) {
      return element[field] ? moment(element[field], 'DD-MM-YYYY, hh:mm ').format('DD/MM/YYYY hh:mm A') : ''
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
