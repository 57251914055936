<template>
  <div>
    <b-form
      @submit.prevent="search"
    >
      <validation-observer
        ref="elementForm"
        #default="{ invalid }"
      >
        <b-card>
          <b-card-title>
            {{ $t('billing.search.section.period') }}
          </b-card-title>
          <b-card-body>
            <b-row>
              <!-- From Date Field -->
              <b-col
                cols="6"
                md="6"
                lg="6"
              >
                <date-picker
                  :element-required="true"
                  element-field="from"
                  :root-element-data="elementData"
                  :element-label="$t('billing.search.field.from')"
                />
              </b-col>
              <!-- Until Date Field -->
              <b-col
                cols="6"
                md="6"
                lg="6"
              >
                <date-picker
                  :element-required="true"
                  element-field="until"
                  :root-element-data="elementData"
                  :element-label="$t('billing.search.field.to')"
                />
              </b-col>
            </b-row>
          </b-card-body>
          <hr>
          <b-card-title>
            {{ $t('billing.search.section.serviceType') }}
          </b-card-title>
          <b-card-body>
            <b-row>
              <b-col
                v-for="serviceType in serviceTypeList"
                :key="serviceType.id"
                cols="3"
              >
                <div class="form-check form-check-inline">
                  <input
                    v-model="elementData.service_types"
                    class="form-check-input"
                    type="checkbox"
                    :value="serviceType.id"
                  >
                  <label
                    class="form-check-label"
                    :for="serviceType.id"
                  >{{ serviceType.value }}</label>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
          <hr>
          <b-card-title>
            {{ $t('billing.search.section.arl') }}
          </b-card-title>
          <b-card-body>
            <!-- ARL Select List Field -->
            <b-row>
              <b-col
                cols="4"
                md="4"
              >
                <b-form-group
                  label-for="arl"
                >
                  <template v-slot:label>
                    {{ $t('billing.search.field.arl') }} <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('billing.search.field.arl')"
                    rules="required"
                  >
                    <v-select
                      v-model="elementData.arl_id"
                      :options="arlList"
                      label="name"
                      value="id"
                      :reduce="option => option.id"
                      :placeholder="getPlaceholderValue(arlList)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="4"
                md="4"
              >
                <b-form-group
                  label-for="arl"
                >
                  <template v-slot:label>
                    {{ $t('billing.search.field.group') }} <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.arl')"
                    rules="required"
                  >
                    <v-select
                      v-model="elementData.size"
                      :options="sizeList"
                      label="value"
                      value="id"
                      :reduce="option => option.id"
                      :placeholder="getPlaceholderValue(sizeList)"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                cols="4"
                md="4"
              >
                <b-form-group
                  label-for="rips"
                >
                  <template v-slot:label>
                    {{ $t('billing.search.field.rips') }} <span class="text-danger">*</span>
                  </template>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t(resourcesElementName + '.object.rips')"
                  >

                    <b-form-radio-group
                      v-model="elementData.rips"
                      :options="ripOptions"
                      class="mb-8 text-center"
                      value-field="value"
                      text-field="name"
                      disabled-field="notEnabled"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-footer>
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1 ml-1"
              :disabled="invalid"
            >
              {{ $t('actions.search') }}
            </b-button>
          </b-card-footer>
        </b-card>

      </validation-observer>
    </b-form>
  </div>

</template>

<script>
import {
  BCard, BCardTitle, BCardFooter, BFormRadioGroup, BCardBody, BButton, BRow, BCol, BFormGroup, BForm,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DatePicker from '@/views/core/DatePicker.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    DatePicker,
    BCard,
    BCardTitle,
    BCardFooter,
    BButton,
    BRow,
    BCol,
    BCardBody,
    BFormGroup,
    BFormRadioGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    caseNumber: {
      bind(el, binding, vnode) {
        el.addEventListener('input', e => {
          const input = e.target
          input.value = input.value.replace(/[^0-9]/g, '').slice(0, 3)
          vnode.componentInstance.$emit('input', input.value)
        })
      },
    },
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    resourcesElementName: {
      type: String,
      required: true,
    },
    arlList: {
      type: Array,
      required: true,
    },
    sizeList: {
      type: Array,
      required: true,
    },
    serviceTypeList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      ripOptions: [
        { value: 1, name: this.$t('billing.search.field.ripsYes') },
        { value: 0, name: this.$t('billing.search.field.ripsNo') },
      ],
    }
  },
  methods: {
    validate(elementData) {
      const errors = []
      if (moment(elementData.from).isAfter(moment(elementData.until))) {
        errors.push(this.$t(`${this.resourcesElementName}.errors.date_comparison`))
      }
      if (errors.length) {
        for (const error of errors) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t(`${this.resourcesElementName}.errors.validations_title`),
              icon: 'SaveIcon',
              variant: 'danger',
              text: error,
            },
          })
        }
        return false
      }
      return true
    },
    search() {
      console.info('elementData:', this.elementData)

      this.$refs.elementForm.validate().then(success => {
        if (success) {
          if (this.validate(this.elementData)) {
            this.$parent.fetchElements()
          }
        }
      })
    },
    getPlaceholderValue(list) {
      const placeholderOption = list.find(option => option.id === null)
      return placeholderOption ? placeholderOption.value : ''
    },
  },
}
</script>

<style>

</style>
